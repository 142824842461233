.modal-lg {
  max-width: 80%;
  width: 1400px;  /* Prevents the modal from becoming too wide on large screens */
}

.fixed-size-modal .modal-content {
  min-height: 600px;
}

.modal-body {
  display: flex;
  flex-direction: column;
}

.ambiguities-list {
  flex: 1;
}


/* Main Tooltip Styling */
.tooltip-inner {
  max-width: 500px !important; /* Allows tooltip to expand if needed */
  width: auto !important;
  white-space: normal !important; /* Allows multiline text */
  text-align: left !important;
  padding: 14px !important; /* More spacious padding */
  font-size: 14px !important;
  line-height: 1.5 !important; /* Better readability */
  word-wrap: break-word !important; /* Prevents overflow */

  background: #ffffff !important; /* Solid white background */
  color: #333 !important; /* Darker text for contrast */
  border-radius: 10px !important; /* Smooth rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important; /* Soft shadow */

  opacity: 1 !important; /* Ensures full visibility */
  filter: none !important; /* Removes unwanted transparency */
}

/* Ensure Tooltip Arrow is Solid */
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  background: #ffffff !important; /* Match tooltip background */
  opacity: 1 !important; /* Remove any transparency */
  filter: none !important; /* Ensure full visibility */
}

/* Adjust Title Inside Tooltip */
.tooltip-inner .title {
  font-weight: 700;
  font-size: 1.1rem;
  color: #2c3e50 !important; /* Darker, more neutral */
  margin-bottom: 6px !important;
}

/* Labels Inside the Tooltip */
.tooltip-inner .label {
  font-weight: 600;
  color: #3b3b3b !important; /* Slightly darker for contrast */
  display: block !important; /* Ensure label is on its own line */
  margin-bottom: 4px !important;
}

/* Content Text Inside the Tooltip */
.tooltip-inner .content {
  font-size: 0.9rem;
  color: #586069 !important; /* Soft gray */
}

/* Ensure No Extra Transparency on Bootstrap Tooltips */
.tooltip.show {
  opacity: 1 !important; /* Fully visible */
  filter: none !important; /* No transparency effects */
}
